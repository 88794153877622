import {useRef, useState, Suspense, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import Loader from '@teladoc/fe-ccm/ui/loader/Loader';
import UserAPI from '@teladoc/fe-ccm/ui/user/user-api';
import UserUtils from '@teladoc/fe-ccm/ui/user/user-utils';
import {userUpdate, userLogout} from '@teladoc/fe-ccm/ui/user/user-actions';
import ProtoUtils from '@teladoc/fe-ccm/ui/common/utilities/proto-utils';
import ReactAxeCore from '@teladoc/fe-ccm/ui/common/axe-core/axe-core';
import {chatConnect} from '@teladoc/fe-ccm/ui/chat/chat-actions';
import {
    getPrimaryRouteById,
    userActionRoutes,
} from '@teladoc/fe-ccm/ui/router/routes';
import {appUpdate} from '@teladoc/fe-ccm/ui/app/app-actions';
import CommonUtils from '@teladoc/fe-ccm/ui/common/utilities/common-utils';
import {FEATURE_FLAG_NAMES} from '@teladoc/fe-ccm/ui/config';
import TeladocSSOForm from '@teladoc/fe-ccm/ui/teladoc-sso-form/TeladocSSOForm';
import TransitionScreen from '@teladoc/fe-ccm/ui/user/client-migration/transition-screen';
import Keycloak from '@teladoc/fe-ccm/ui/user/client-migration/Keycloak';
import MobileMigration from '@teladoc/fe-ccm/ui/user/client-migration/MobileMigration';
// TODO: uncomment out to change to Spain languages in your local
// usefull for L10N development while we want for Spain envs to be setup
import {
    useI18nContext,
    useG11nBidirectionalStateManager,
} from '@teladoc/pulse/ui/Context/i18n';
import Arg from '@livongo/arg';
import {useFeatureFlagContext} from '@livongo/utilities/system/featureFlag';
import StorageUtils from '@livongo/utilities/system/storage';
import config from '~/config';
import App from './App';
import RoutePublic from './RoutePublic';

const mfa = getPrimaryRouteById('mfa');
const dashboard = getPrimaryRouteById('dashboard');
const userRoutes = userActionRoutes.filter(
    route =>
        route.id !== 'logout' &&
        route.id !== 'transitionScreen' &&
        route.id !== 'keycloak' &&
        route.id !== 'migration'
);
const PROGRAM_ROUTES = {
    DIABETES: 'bloodSugar',
    HYPERTENSION: 'bloodPressure',
    WEIGHT_MANAGEMENT: 'weight',
    PREDIABETES: null,
    // - Programs that don't have a specific URL will be redirected to /dashboard
    // - Behavioral health will be redirected to the myStrength website
    HEART_FAILURE: null,
    CHRONIC_KIDNEY_DISEASE: null,
    FOOD: 'food',
};

const Root = () => {
    const dispatch = useDispatch();
    const {changeLanguage, selectedLang, scopedLangCodes} = useI18nContext();
    const {featureFlags} = useFeatureFlagContext();
    // hasLoggedInRef guarantee that the LanguageConfirmationModal can only be shown right after login
    const hasLoggedInRef = useRef(false);
    const {
        isAuthenticated,
        userLocale,
        mfaToken,
        activePrograms,
        activePrograms: {behavioralHealth: hasBehavioralHealth},
    } = useSelector(state => state.user);
    const canUpsellBehavioralHealth = useSelector(({user}) =>
        user.programs.some(
            ({program, status}) =>
                program === 'BEHAVIORAL_HEALTH' && status === 'OFFERED'
        )
    );
    const {hasChatAvailable} = useSelector(state => state.app);
    const [teladocSSOData, setTeladocSSOData] = useState(null);
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [enableGlpEducation, setEnableGlpEducation] = useState(
        CommonUtils.isFeatureEnabled(
            featureFlags,
            FEATURE_FLAG_NAMES.enableGlpEducation,
            false
        )
    ); // Todo: must remove this flag
    const ssoCtx = Arg('ssoCtx');
    const partnerId = Arg('partnerId');
    const redirectToEducation = Arg('redirectToEducation');
    // The URL param ?ssoAccessCode=CODE is a strategy to associate a Teladoc and a Livongo account belonging to the same member.
    // That param is added to the URL when a member is redirected from Teladoc and after auth succeeds Member Portal makes a API request to associate the accounts.
    const connectCode = useRef(Arg('ssoAccessCode'));
    // A URL param ?program=[DIABETES|HYPERTENSION|HEART_FAILURE|etc] can define which program's page the app should redirect to
    const programId = useRef(Arg('program')?.toUpperCase());
    const mfaOneappRedirect = Arg('oneapp');
    const mfaRedirectURL = Arg('callback');
    const routeId = PROGRAM_ROUTES[programId.current];
    const source = Arg('source');
    const utmSource = Arg('utm_source');
    const utmMedium = Arg('utm_medium');
    let successfulPath = dashboard.path;
    /* eslint-disable camelcase */
    const utmParams = JSON.stringify({
        utm_source: utmSource,
        utm_medium: utmMedium,
    });
    /* eslint-enable camelcase */
    // Set the combined parameters into a single cookie

    CommonUtils.setCookie({
        key: config.UTM_PARAMETERS,
        value: utmParams,
    });

    // Temporary solution to provide support for iOS once they have this feature in place (around SEPTEMBER) we will remove this
    if (source && source.includes('food_insights')) {
        successfulPath = config.FOOD_INSIGHTS_PATH;
    }
    const loginSuccessLocation = routeId
        ? getPrimaryRouteById(routeId).path
        : successfulPath;
    const onLanguageModalToggle = () => {
        setShowLanguageModal(show => !show);
    };
    const isBloodPressureRetakeLink =
        window.location.href.includes('/l/followup');

    useG11nBidirectionalStateManager(
        userLocale,
        useCallback(
            code => {
                dispatch(userUpdate({userLocale: code}));
            },
            [dispatch]
        )
    );

    // Handle reaching Livongo with a locale parameter in the URL, ie locale=en-US
    useEffect(() => {
        const locale = Arg('locale');

        if (locale && scopedLangCodes.includes(locale)) {
            changeLanguage(locale);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle user logging in with a different language than their preferred language on the backend
    useEffect(() => {
        const hasLoggedIn = isAuthenticated && !hasLoggedInRef.current;
        const hasSeenLanguageModal = StorageUtils.get({
            key: config.LANGUAGE_MODAL_KEY,
        });

        if (
            userLocale !== selectedLang &&
            hasLoggedIn &&
            !hasSeenLanguageModal
        ) {
            setShowLanguageModal(true);

            StorageUtils.set({key: config.LANGUAGE_MODAL_KEY, value: true});
        }

        if (hasLoggedIn) {
            hasLoggedInRef.current = true;
        }
    }, [selectedLang, userLocale, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            if (partnerId && ssoCtx) {
                UserAPI.getPartnerRedirectData({
                    partnerId,
                    config: {
                        params: {
                            ssoCtx,
                        },
                    },
                })
                    .then(({ssoStatus, uri, parameters}) => {
                        const {ESTABLISHED, FAILURE} = UserUtils.SSO_STATUS;

                        if (ssoStatus === ESTABLISHED && uri) {
                            setTeladocSSOData({uri, parameters});
                        } else if (ssoStatus === FAILURE) {
                            throw Error;
                        }
                    })
                    .catch(() => {
                        dispatch(userLogout());
                        dispatch(appUpdate({hasTeladocSSOError: true}));
                    });
            }

            if (connectCode.current) {
                UserAPI.associateAccount(connectCode.current);

                if (
                    mfaOneappRedirect === true &&
                    typeof mfaRedirectURL !== 'undefined'
                ) {
                    window.location.replace(
                        `${mfaRedirectURL}?from_registration=true`
                    );
                }
            }

            if (
                programId.current === 'BEHAVIORAL_HEALTH' &&
                !mfaOneappRedirect
            ) {
                if (hasBehavioralHealth) {
                    window.open(
                        UserUtils.appendParamToUrl({
                            url: process.env.MYSTRENGTH_URL,
                            param: {
                                language: userLocale,
                            },
                        }),
                        '_self'
                    );
                }

                if (canUpsellBehavioralHealth) {
                    window.open(
                        `${UserUtils.appendParamToUrl({
                            url: `${window.location.origin}/programs/?scrollTo=available`,
                            param: {locale: userLocale},
                        })}`,
                        '_self'
                    );
                }
            }

            programId.current = null;
            connectCode.current = null;
        }
    }, [
        isAuthenticated,
        userLocale,
        connectCode,
        dispatch,
        programId,
        partnerId,
        ssoCtx,
        hasBehavioralHealth,
        canUpsellBehavioralHealth,
        mfaOneappRedirect,
        mfaRedirectURL,
    ]);

    useEffect(() => {
        ProtoUtils.setHeaders({'Accept-Language': selectedLang});

        // re-dispatch chatConnect to make sure that we're sending along the correct, updated language to Sendbird
        if (hasChatAvailable) {
            dispatch(chatConnect());
        }
    }, [selectedLang, dispatch, hasChatAvailable]);

    useEffect(() => {
        UserUtils.updateHeaders({'Accept-Language': selectedLang});
    }, [selectedLang]);

    useEffect(() => {
        if (isBloodPressureRetakeLink) {
            dispatch(appUpdate({isBloodPressureRetakeFromLink: true}));
        }
    }, [isBloodPressureRetakeLink, dispatch]);

    // This is used to refresh the user's education session when it times out due to inactivity
    const refreshEducationSession = useCallback(() => {
        if (!isAuthenticated) {
            window.history.pushState({}, '', getPrimaryRouteById('login').path);

            return;
        }

        CommonUtils.openEducationPortal({
            enableGlpEducation,
            activePrograms,
            isOneApp: false,
        });
    }, [isAuthenticated, enableGlpEducation, activePrograms]);

    useEffect(() => {
        if (redirectToEducation) {
            refreshEducationSession();
        }
    }, [redirectToEducation, refreshEducationSession]);

    useEffect(() => {
        setEnableGlpEducation(
            CommonUtils.isFeatureEnabled(
                featureFlags,
                FEATURE_FLAG_NAMES.enableGlpEducation,
                false
            )
        );
    }, [featureFlags]);

    /**
     * Initializing @axe-core/react
     * https://github.com/dequelabs/axe-core-npm/blob/develop/packages/react/README.md
     */
    ReactAxeCore({loadScript: config.IS_NON_PROD});

    return (
        <>
            {/* TODO: uncomment out to change to Spain languages
                usefull for L10N development while we want for Spain envs to be setup
            <RegionSelect />
            */}
            <Router>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route exact path="/">
                            {mfaToken ? (
                                <Redirect
                                    to={{
                                        pathname: mfa.path,
                                        state: {
                                            mfaToken,
                                            loginSuccessLocation,
                                        },
                                    }}
                                />
                            ) : (
                                <Redirect to={loginSuccessLocation} />
                            )}
                        </Route>
                        <Route exact path="/l/followup">
                            <Redirect to={loginSuccessLocation} />
                        </Route>
                        {userRoutes.map(({id, path, component}) => {
                            return (
                                <RoutePublic
                                    exact
                                    id={id}
                                    key={id}
                                    path={path}
                                    component={component}
                                />
                            );
                        })}
                        <Route
                            exact
                            path="/transitionScreen"
                            render={() => (
                                <TransitionScreen mfaToken={mfaToken} />
                            )}
                        />
                        <Route
                            exact
                            path="/keycloak"
                            render={() => <Keycloak />}
                        />
                        <Route
                            exact
                            path="/migration"
                            render={() => <MobileMigration />}
                        />
                        {isAuthenticated && partnerId && ssoCtx ? (
                            <Loader />
                        ) : (
                            <Route
                                path="/"
                                render={props => (
                                    <App
                                        {...props}
                                        showLanguageModal={showLanguageModal}
                                        onLanguageModalToggle={
                                            onLanguageModalToggle
                                        }
                                    />
                                )}
                            />
                        )}
                    </Switch>
                </Suspense>
            </Router>
            {teladocSSOData && <TeladocSSOForm data={teladocSSOData} />}
        </>
    );
};

export default Root;
